import * as option from "@/models/configurator/configurator_options";

import { CanopyContext, CanopyMutations } from "./types";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { FabricSwatch, FixedFabricArray } from "@/models/products/fabric";

export const addMainCanopy = (context: CanopyContext, value: option.Panel) => {
  context.commit(CanopyMutations.SetMainCanopy, value);
};

export const addMainFabric = (
  context: CanopyContext,
  value: FixedFabricArray
) => {
  context.commit(CanopyMutations.SetMainFabric, value);
};

export const addVentTopFabric = (
  context: CanopyContext,
  value: FabricSwatch | []
) => {
  context.commit(CanopyMutations.SetVentTopFabric, value);
};

export const addVentMiddle = (context: CanopyContext, value: option.Panel) => {
  context.commit(CanopyMutations.SetVentMiddle, value);
};

export const addVentMiddleFabric = (
  context: CanopyContext,
  value: FixedFabricArray
) => {
  context.commit(CanopyMutations.SetVentMiddleFabric, value);
};

export const addValanceFabric = (
  context: CanopyContext,
  value: FixedFabricArray
) => {
  context.commit(CanopyMutations.SetValanceFabric, value);
};

export const addValance = (context: CanopyContext, value: option.Valance) => {
  context.commit(CanopyMutations.SetValance, value);
};

export const addBindingFabric = (
  context: CanopyContext,
  value: FabricSwatch | null
) => {
  context.commit(CanopyMutations.SetBindingFabric, value);
};

export const addRib = (context: CanopyContext, value: option.Rib | null) => {
  context.commit(CanopyMutations.SetRib, value);
};

export const addRibFabric = (
  context: CanopyContext,
  value: FabricSwatch | null
) => {
  context.commit(CanopyMutations.SetRibFabric, value);
};

export const addMainTrimFabric = (
  context: CanopyContext,
  value: FabricSwatch | null
) => {
  context.commit(CanopyMutations.SetMainTrimFabric, value);
};

export const addMainTrimFabricInner = (
  context: CanopyContext,
  value: FabricSwatch | null
) => {
  context.commit(CanopyMutations.SetMainTrimFabricInner, value);
};

export const addVentTrimFabric = (
  context: CanopyContext,
  value: FabricSwatch | null
) => {
  context.commit(CanopyMutations.SetVentTrimFabric, value);
};

export const addFringeFabric = (
  context: CanopyContext,
  value: FixedFabricArray
) => {
  context.commit(CanopyMutations.SetFringeFabric, value);
};

export const addFringe = (context: CanopyContext, value: option.Fringe) => {
  context.commit(CanopyMutations.SetFringe, value);
};

export const addAllCanopyData = (
  context: CanopyContext,
  value: CustomUmbrella["canopy"]
) => {
  context.commit(CanopyMutations.SetMainCanopy, value.mainCanopy);
  context.commit(CanopyMutations.SetMainFabric, value.mainFabric);
  context.commit(CanopyMutations.SetVentTopFabric, value.ventTopFabric);
  context.commit(CanopyMutations.SetVentMiddle, value.ventMiddle);
  context.commit(CanopyMutations.SetVentMiddleFabric, value.ventMiddleFabric);
  context.commit(CanopyMutations.SetValance, value.valance);
  context.commit(CanopyMutations.SetValanceFabric, value.valanceFabric);
  context.commit(CanopyMutations.SetBindingFabric, value.bindingFabric);
  context.commit(CanopyMutations.SetRib, value.rib);
  context.commit(CanopyMutations.SetRibFabric, value.ribFabric);
  context.commit(CanopyMutations.SetMainTrimFabric, value.mainTrimFabric);
  context.commit(
    CanopyMutations.SetMainTrimFabricInner,
    value.mainTrimFabricInner
  );
  context.commit(CanopyMutations.SetVentTrimFabric, value.ventTrimFabric);
  context.commit(CanopyMutations.SetFringe, value.fringe);
  context.commit(CanopyMutations.SetFringeFabric, value.fringeFabric);
};
