import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
} from "@/mixins/store";
import { Collection } from "@/models/products/collection";
import { UmbrellaModel } from "@/models/products/umbrella";
import { BaseModel } from "@/models/products/base";
import { ProductType, SingleProductRes } from "@/models/products/product";
import ProductService from "@/services/product_service";
import FabricService from "@/services/fabric_service";
import { Preset } from "@/models/configurator/configurator_options";
import { APIError, NotFoundError } from "@/services/error_service";
import { ConfigFile, StartAppFile } from "@/models/configurator/config_file";
import {
  Finish,
  FinishDisplayName,
  FinishHandle,
} from "@/models/products/finish";
import * as option from "@/models/configurator/configurator_options";
import { FabricSwatch } from "@/models/products/fabric";
import { StockMixin } from "./stock";
@Component
export class ConfigCreator extends mixins(
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
  StockMixin
) {
  protected productService = new ProductService();
  protected fabricService = new FabricService();

  protected async transformDataToViewerJson(): Promise<StartAppFile> {
    const fabricArray = [];
    if (this.mainFabric) {
      for (const swatch of this.mainFabric) {
        fabricArray.push(swatch.fabric_scale, swatch.mfr_code);
      }
    }
    const fabricArrayVentTop = [];
    if (this.ventTopFabric) {
      if (this.ventTopFabric.length > 0) {
        for (const swatch of this.ventTopFabric) {
          fabricArrayVentTop.push(swatch.fabric_scale, swatch.mfr_code);
        }
      }
    }
    const fabricArrayVentMid = [];
    if (this.ventMiddleFabric) {
      if (this.ventMiddleFabric.length > 0) {
        for (const swatch of this.ventMiddleFabric) {
          fabricArrayVentMid.push(swatch.fabric_scale, swatch.mfr_code);
        }
      }
    }
    const fabricArrayValance = [];
    if (this.valanceFabric) {
      if (this.valanceFabric.length > 0) {
        for (const swatch of this.valanceFabric) {
          fabricArrayValance.push(swatch.fabric_scale, swatch.mfr_code);
        }
      }
    }
    const fabricArrayFringe = [];
    if (this.fringeFabric) {
      if (this.fringeFabric.length > 0) {
        for (const swatch of this.fringeFabric) {
          fabricArrayFringe.push(swatch.fabric_scale, swatch.mfr_code);
        }
      }
    }
    const data = {
      layout: {
        preset: this.preset,
        collection: { handle: this.collection.handle },
      },
      shape: { model: this.umbrellaModel!.model },
      base: {
        base: this.baseModel ? this.baseModel.model : null,
        baseFinish: this.baseFinish ? this.baseFinish.handle : null,
        baseStem: this.baseStem,
      },
      frame: {
        finish: this.finish.handle,
        finial: this.finial,
        finialFinish: this.finialFinish.handle,
        bottomPole: this.bottomPole,
      },
      canopy: {
        mainCanopy: this.mainCanopy,
        mainTrimFabric: this.mainTrimFabric
          ? this.mainTrimFabric.mfr_code
          : null,
        mainTrimFabricInner: this.mainTrimFabricInner
          ? this.mainTrimFabricInner.mfr_code
          : null,
        mainFabric: fabricArray,
        ventTopFabric: fabricArrayVentTop,
        ventMiddle: this.ventMiddle,
        ventMiddleFabric: fabricArrayVentMid,
        ventTrimFabric: this.ventTrimFabric
          ? this.ventTrimFabric.mfr_code
          : null,
        valance: this.valance,
        valanceFabric: fabricArrayValance,
        bindingFabric: this.bindingFabric ? this.bindingFabric.mfr_code : null,
        rib: this.rib,
        ribFabric:
          !this.rib || this.rib === option.Rib.Grommets ? null : fabricArray[1], // 0 would be fabric scale
        fringe: this.fringe,
        fringeFabric: fabricArrayFringe,
      },
    } as StartAppFile;
    return data;
  }

  protected async transformDataToConfigFile(): Promise<ConfigFile> {
    const fabricArray = this.mainFabric.map((fabric) => fabric.mfr_code);
    const fabricArrayVentTop = this.ventTopFabric.map(
      (fabric) => fabric.mfr_code
    );
    const fabricArrayVentMid = this.ventMiddleFabric.map(
      (fabric) => fabric.mfr_code
    );
    const fabricArrayValance = this.valanceFabric.map(
      (fabric) => fabric.mfr_code
    );
    const fabricArrayFringe = this.fringeFabric.map(
      (fabric) => fabric.mfr_code
    );

    const data = {
      layout: {
        preset: this.preset,
        collection: { handle: this.collection.handle },
      },
      shape: { model: this.umbrellaModel!.model },
      base: {
        base: this.baseModel ? this.baseModel.model : null,
        baseFinish: this.baseFinish ? this.baseFinish.handle : null,
        baseStem: this.baseStem,
      },
      frame: {
        finish: this.finish.handle,
        finial: this.finial,
        finialFinish: this.finialFinish.handle,
        bottomPole: this.bottomPole,
        spigot: this.spigot,
      },
      canopy: {
        mainCanopy: this.mainCanopy,
        mainTrimFabric: this.mainTrimFabric
          ? this.mainTrimFabric.mfr_code
          : null,
        mainTrimFabricInner: this.mainTrimFabricInner
          ? this.mainTrimFabricInner.mfr_code
          : null,
        mainFabric: fabricArray,
        ventTopFabric: fabricArrayVentTop,
        ventMiddle: this.ventMiddle,
        ventMiddleFabric: fabricArrayVentMid,
        valanceFabric: fabricArrayValance,
        ventTrimFabric: this.ventTrimFabric
          ? this.ventTrimFabric.mfr_code
          : null,
        valance: this.valance,
        bindingFabric: this.bindingFabric ? this.bindingFabric.mfr_code : null,
        rib: this.rib,
        ribFabric:
          !this.rib || this.rib === option.Rib.Grommets ? null : fabricArray[0],
        fringe: this.fringe,
        fringeFabric: fabricArrayFringe
      },
    };
    return data;
  }

  protected setStockDefault(stock: boolean | undefined = undefined) {
    if (stock) {
      this.addStock(stock);
    } else {
      this.addStock(false);
    }
  }

  protected async transformConfigFileAndSaveToStore(
    file: ConfigFile
  ): Promise<void> {
    try {
      const data: any = { ...file };
      const fabricArray = [];
      for (const fabricCode of file.canopy.mainFabric.filter(
        (e: any) => typeof e === "string"
      )) {
        const swatch = await this.getFabricSwatch(fabricCode);
        if (!swatch || this.isEmptyObject(swatch)) {
          throw new Error();
        }
        fabricArray.push(swatch);
      }
      data.canopy.mainFabric = fabricArray;
      const fabricArrayVentTop = [];
      for (const fabricCode of file.canopy.ventTopFabric.filter(
        (e: any) => typeof e === "string"
      )) {
        const swatch = await this.getFabricSwatch(fabricCode);
        if (!swatch || this.isEmptyObject(swatch)) {
          throw new Error();
        }
        fabricArrayVentTop.push(swatch);
      }
      data.canopy.ventTopFabric = fabricArrayVentTop;
      if (file.canopy.ventMiddleFabric.length > 0) {
        const fabricArrayVentMid = [];
        for (const fabricCode of file.canopy.ventMiddleFabric.filter(
          (e: any) => typeof e === "string"
        )) {
          const swatch = await this.getFabricSwatch(fabricCode);
          if (!swatch || this.isEmptyObject(swatch)) {
            throw new Error();
          }
          fabricArrayVentMid.push(swatch);
        }
        data.canopy.ventMiddleFabric = fabricArrayVentMid;
      }
      if (file.canopy.valanceFabric.length > 0) {
        const fabricArrayValance = [];
        for (const fabricCode of file.canopy.valanceFabric.filter(
          (e: any) => typeof e === "string"
        )) {
          const swatch = await this.getFabricSwatch(fabricCode);
          if (!swatch || this.isEmptyObject(swatch)) {
            throw new Error();
          }
          fabricArrayValance.push(swatch);
        }
        data.canopy.valanceFabric = fabricArrayValance;
      }
      if (data.canopy.bindingFabric) {
        data.canopy.bindingFabric = await this.getFabricSwatch(
          file.canopy.bindingFabric!
        );
      }
      if (file.canopy.fringeFabric.length > 0) {
        const fabricArrayFringe = [];
        for (const fabricCode of file.canopy.fringeFabric.filter(
          (e: any) => typeof e === "string"
        )) {
          const swatch = await this.getFabricSwatch(fabricCode);
          if (!swatch || this.isEmptyObject(swatch)) {
            throw new Error();
          }
          fabricArrayFringe.push(swatch);
        }
        data.canopy.fringeFabric = fabricArrayFringe;
      }
      if (data.canopy.ribFabric) {
        data.canopy.ribFabric = fabricArray[0];
      }
      if (data.canopy.mainTrimFabric) {
        data.canopy.mainTrimFabric = await this.getFabricSwatch(
          file.canopy.mainTrimFabric!
        );
      }
      if (data.canopy.mainTrimFabricInner) {
        data.canopy.mainTrimFabricInner = await this.getFabricSwatch(
          file.canopy.mainTrimFabricInner!
        );
      }
      if (data.canopy.ventTrimFabric) {
        data.canopy.ventTrimFabric = await this.getFabricSwatch(
          file.canopy.ventTrimFabric!
        );
      }
      const umbrellaCollection: Collection = await this.getUmbrellaProductLines(
        data.layout.productline.handle
      );
      const addCollectionStorePromise = await this.addCollection(umbrellaCollection);
      const addPresetStorePromise = await this.addPreset(data.layout.preset ? data.layout.preset : Preset.S1);
      const model: UmbrellaModel = await this.getUmbrellaModel(data.shape.model);
      if (!model || this.isEmptyObject(model)) {
        throw new Error();
      }
      const addUmbrellaModelStorePromise = await this.addUmbrellaModel(model);
      let base: BaseModel | null = null;
      if (data.base.base) {
        //base can be null
        base = await this.getBaseModel(data.base.base);
      }
      const addAllBaseDataStorePromise = await this.addAllBaseData({
        base: base,
        baseFinish: base ? this.getFinish(data.base.baseFinish) : null,
        baseStem: base ? data.base.baseStem : null,
        baseOptions: base ? data.base.baseOptions : null
      });
      const canopy: any = { ...data.canopy };
      if (canopy.mainFabric.length > 1) {
        canopy.mainCanopy = option.Panel.Alternating;
      } else {
        canopy.mainCanopy = option.Panel.Solid;
      }
      if (canopy.ventMiddleFabric.length > 1) {
        canopy.ventMiddle = option.Panel.Alternating;
      } else {
        canopy.ventMiddle = option.Panel.Solid;
      }
      const addAllCanopyDataStorePromise = await this.addAllCanopyData(canopy);
      const frame: any = { ...data.frame };
      frame.finialFinish = this.getFinish(data.frame.finialFinish);
      frame.finish = this.getFinish(data.frame.finish);
      const addAllFrameDataStorePromise = await this.addAllFrameData(frame);

      await Promise.all([
        addCollectionStorePromise,
        addPresetStorePromise,
        addUmbrellaModelStorePromise,
        addAllBaseDataStorePromise,
        addAllCanopyDataStorePromise,
        addAllFrameDataStorePromise,
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  protected async getFabricSwatch(mfrCode: string): Promise<FabricSwatch> {
    let swatch = {} as FabricSwatch;
    try {
      const res: FabricSwatch = await this.fabricService.getSingleFabric(
        mfrCode
      );
      swatch = res;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return swatch;
  }

  protected async getUmbrellaModel(model: string): Promise<UmbrellaModel> {
    let umbrella = {} as UmbrellaModel;
    try {
      const res: SingleProductRes = (await this.productService.getSingleProduct(
        model
      )) as SingleProductRes;
      umbrella = res.product as any;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return umbrella;
  }

  protected async getBaseModel(model: string): Promise<BaseModel> {
    let base = {} as BaseModel;
    try {
      const res: SingleProductRes = (await this.productService.getSingleProduct(
        model
      )) as SingleProductRes;
      base = res.product as any;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return base;
  }

  protected async getUmbrellaProductLines(handle: string): Promise<Collection> {
    let umbrellas: Collection[] = [];
    try {
      const res = await this.productService.getProducts({
        category: ProductType.Umbrella,
        sort: "productline",
      });
      umbrellas = res.filter((umbrella) => umbrella.handle === handle);
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return umbrellas[0];
  }

  protected getFinish(stringHandle: FinishHandle): Finish {
    const handle = stringHandle as FinishHandle;
    let obj = {} as Finish;
    switch (handle) {
      case FinishHandle.MS:
        obj = { handle: FinishHandle.MS, display_name: FinishDisplayName.MS };
        break;
      case FinishHandle.WG:
        obj = { handle: FinishHandle.WG, display_name: FinishDisplayName.WG };
        break;
      case FinishHandle.BK:
        obj = { handle: FinishHandle.BK, display_name: FinishDisplayName.BK };
        break;
      case FinishHandle.WH:
        obj = { handle: FinishHandle.WH, display_name: FinishDisplayName.WH };
        break;
      case FinishHandle.BZ:
        obj = { handle: FinishHandle.BZ, display_name: FinishDisplayName.BZ };
        break;
      case FinishHandle.SR:
        obj = { handle: FinishHandle.SR, display_name: FinishDisplayName.SR };
        break;
      case FinishHandle.HW:
        obj = { handle: FinishHandle.HW, display_name: FinishDisplayName.HW };
        break;
      case FinishHandle.MSA:
        obj = { handle: FinishHandle.MSA, display_name: FinishDisplayName.MSA };
        break;
      case FinishHandle.SM:
        obj = { handle: FinishHandle.SM, display_name: FinishDisplayName.SM };
        break;
      case FinishHandle.SS:
        obj = { handle: FinishHandle.SS, display_name: FinishDisplayName.SS };
        break;
      case FinishHandle.GS:
        obj = { handle: FinishHandle.GS, display_name: FinishDisplayName.GS };
        break;
      case FinishHandle.ASH:
        obj = { handle: FinishHandle.ASH, display_name: FinishDisplayName.ASH };
        break;
      case FinishHandle.GBK:
        obj = { handle: FinishHandle.GBK, display_name: FinishDisplayName.GBK };
        break;
      case FinishHandle.CB:
        obj = { handle: FinishHandle.CB, display_name: FinishDisplayName.CB };
        break;
    }
    return obj;
  }

  protected isEmptyObject(obj: any): boolean {
    return (
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    );
  }
}
