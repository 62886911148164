import * as option from "@/models/configurator/configurator_options";

import { FabricSwatch, FixedFabricArray } from "@/models/products/fabric";

import { CanopyState } from "./types";

export const setMainCanopy = (state: CanopyState, value: option.Panel) => {
  state.mainCanopy = value;
};

export const setMainFabric = (state: CanopyState, value: FixedFabricArray) => {
  state.mainFabric = value;
};

export const setVentTopFabric = (
  state: CanopyState,
  value: FixedFabricArray | []
) => {
  state.ventTopFabric = value;
};

export const setVentMiddle = (state: CanopyState, value: option.Panel) => {
  state.ventMiddle = value;
};

export const setVentMiddleFabric = (
  state: CanopyState,
  value: FixedFabricArray
) => {
  state.ventMiddleFabric = value;
};

export const setValanceFabric = (
  state: CanopyState,
  value: FixedFabricArray
) => {
  state.valanceFabric = value;
};

export const setValance = (state: CanopyState, value: option.Valance) => {
  state.valance = value;
};

export const setBindingFabric = (
  state: CanopyState,
  value: FabricSwatch | null
) => {
  state.bindingFabric = value;
};

export const setRib = (state: CanopyState, value: option.Rib | null) => {
  state.rib = value;
};

export const setRibFabric = (
  state: CanopyState,
  value: FabricSwatch | null
) => {
  state.ribFabric = value;
};

export const setMainTrimFabric = (
  state: CanopyState,
  value: FabricSwatch | null
) => {
  state.mainTrimFabric = value;
};

export const setMainTrimFabricInner = (
  state: CanopyState,
  value: FabricSwatch | null
) => {
  state.mainTrimFabricInner = value;
};

export const setVentTrimFabric = (
  state: CanopyState,
  value: FabricSwatch | null
) => {
  state.ventTrimFabric = value;
};

export const setFringeFabric = (
  state: CanopyState,
  value: FixedFabricArray
) => {
  state.fringeFabric = value;
};

export const setFringe = (state: CanopyState, value: option.Fringe) => {
  state.fringe = value;
};
