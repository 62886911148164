import { CustomUmbrella } from "@/models/configurator/custom_umbrella";

export type CanopyState = CustomUmbrella["canopy"];

export type CanopyContext = {
  state: CanopyState;
  dispatch: (action: CanopyActions, payload?: any) => any;
  commit: (mutation: CanopyMutations, payload: any) => any;
};

export enum CanopyMutations {
  SetMainCanopy = "setMainCanopy",
  SetMainFabric = "setMainFabric",
  SetVentTopFabric = "setVentTopFabric",
  SetVentMiddle = "setVentMiddle",
  SetVentMiddleFabric = "setVentMiddleFabric",
  SetValance = "setValance",
  SetBindingFabric = "setBindingFabric",
  SetRib = "setRib",
  SetRibFabric = "setRibFabric",
  SetMainTrimFabric = "setMainTrimFabric",
  SetMainTrimFabricInner = "setMainTrimFabricInner",
  SetVentTrimFabric = "setVentTrimFabric",
  SetValanceFabric = "setValanceFabric",
  SetFringe = "setFringe",
  SetFringeFabric = "setFringeFabric"
}

export enum CanopyActions {
  AddMainCanopy = "addMainCanopy",
  AddMainFabric = "addMainFabric",
  AddVentTopFabric = "addVentTopFabric",
  AddVentMiddle = "addVentMiddle",
  AddVentMiddleFabric = "addVentMiddleFabric",
  AddValance = "addValance",
  AddBindingFabric = "addBindingFabric",
  AddRib = "addRib",
  AddRibFabric = "addRibFabric",
  AddMainTrimFabric = "addMainTrimFabric",
  AddMainTrimFabricInner = "addMainTrimFabricInner",
  AddVentTrimFabric = "addVentTrimFabric",
  AddValanceFabric = "addValanceFabric",
  AddFringe = "addFringe",
  AddFringeFabric = "addFringeFabric",
  AddAllCanopyData = "addAllCanopyData"
}

export enum CanopyGetters {
  GetMainCanopy = "getMainCanopy",
  GetMainFabric = "getMainFabric",
  GetVentTopFabric = "getVentTopFabric",
  GetVentMiddle = "getVentMiddle",
  GetVentMiddleFabric = "getVentMiddleFabric",
  GetValance = "getValance",
  GetValanceFabric = "getValanceFabric",
  GetBindingFabric = "getBindingFabric",
  GetRib = "getRib",
  GetRibFabric = "getRibFabric",
  GetMainTrimFabric = "getMainTrimFabric",
  GetMainTrimFabricInner = "getMainTrimFabricInner",
  GetVentTrimFabric = "getVentTrimFabric",
  GetFringe = "getFringe",
  GetFringeFabric = "getFringeFabric",
  GetAllCanopyData = "getAllCanopyData"
}
