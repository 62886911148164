import { FabricSwatch } from "../products/fabric";
import { FinishHandle } from "../products/finish";
import { UmbrellaModel } from "../products/umbrella";
import * as option from "./configurator_options";

export type ConfigFile = {
  _id?: string;
  public?: boolean;
  type?: ConfigType;
  stock?: boolean;
  layout: {
    collection?: {
      handle: string;
    };
    productline?: {
      handle: string;
    };
    preset: option.Preset;
  };
  shape: {
    model: string;
  };
  base: {
    base: string | null;
    baseFinish: FinishHandle | null;
    baseStem: option.Stem | null;
  };
  frame: {
    finish: FinishHandle;
    finial: option.Finial;
    finialFinish: FinishHandle;
    bottomPole: option.BottomPole;
    spigot: option.Spigot | null;
  };
  canopy: {
    mainCanopy: option.Panel;
    mainFabric: string[]; // Array will contain 2 fabric mfr_codes if alternating panels, else 1
    mainTrimFabric: string | null;
    mainTrimFabricInner: string | null;
    ventTopFabric: string[];
    ventMiddle: option.Panel | null;
    ventMiddleFabric: string[]; // Array will contain 2 fabric mfr_codes if alternating panels, else 1
    ventTrimFabric: string | null;
    valance: option.Valance;
    valanceFabric: string[]; // if valance is present, array will contain 2 fabric mfr_codes if main canopy alternating panels, else 1
    bindingFabric: string | null;
    rib: option.Rib;
    ribFabric: string | null; // null if rib === 'grommet'
    fringe: option.Fringe;
    fringeFabric: string[]; 
  };
  name?: string;
  image?: string;
  creator_id?: string;
  share_id?: string;
  created_at?: Date | string;
  updated_at?: Date | string;
};

export interface QuickshipConfigFile extends ConfigFile {
  type: ConfigType.QS;
  umbrella?: UmbrellaModel;
  fabric?: FabricSwatch;
}

/** This model accounts for fabric scale, which is passed into viewer but is not stored in the config file or vuex store. Scales are assigned to each individual fabric swatch, see fabric model. */
export interface StartAppFile extends Omit<ConfigFile, "canopy"> {
  canopy: {
    mainFabric: [number, string, number?, string?];
    ventTopFabric: [number, string];
    ventMiddleFabric: [number, string, number?, string?] | [];
    valanceFabric: [number, string, number?, string?] | [];
    mainCanopy: option.Panel;
    mainTrimFabric: string | null;
    mainTrimFabricInner: string | null;
    ventMiddle: option.Panel | null;
    ventTrimFabric: string | null;
    valance: option.Valance;
    bindingFabric: string | null;
    rib: option.Rib;
    ribFabric: string | null; // null if rib === 'grommet'
    fringe: option.Fringe;
    fringeFabric: string[]; 
  };
}

export enum ConfigType {
  QS = "quickship",
}
